
export type ErrorType = { code: string, message: string } | string | null | undefined;

/**
 * 取得錯誤訊息
 * @author Ac
 * @since 2021 / 04 / 15
 */
const getErrorDescription = (
  error: ErrorType,
  {
    showErrorCode = true,
    defaultDescription = '發生無法預期的錯誤，請稍後再試。',
  }: {
    showErrorCode?: boolean,
    defaultDescription?: string,
  } = {}
): string =>
    error && typeof error === 'object' && error.message
    ? `${error.message}${error.code && showErrorCode ? `,\n錯誤代碼: ${error.code}` : ''}`
    : typeof error === 'string'
      ? error
      : defaultDescription;


const errorUtil = {
  getErrorDescription, // 取得錯誤訊息
};

export default errorUtil;