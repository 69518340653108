import { memo, useRef } from 'react'
import { Layout } from 'antd';
import styled from 'styled-components';

/**
 * Styled
 */
const Container = styled(Layout.Footer)`
  text-align: center;
`;

const Footer = () => {

  const year = useRef<number>(new Date().getFullYear()).current;
  
  return (
    <Container>
      淞果數位股份有限公司Copyright©{year > 2021 ? `2021-${year}` : year} SoCool Digital CO.,LTD. All Rights Reserved.
    </Container>
  );
};

export default memo(Footer, () => true);
