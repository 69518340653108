import { notification } from 'antd';
import errorUtil, { ErrorType } from '../../../utils/errorUtil';


/**
 * 顯示錯誤訊息視窗
 * @author Ac
 * @since 2021 / 05 / 05
 */
function errorNotification(message: string, error?: ErrorType): void {

  if (error !== void 0) {
    notification.error({
      message,
      description: `錯誤資訊:「 ${errorUtil.getErrorDescription(error)}」`,
      placement: 'bottomRight',
      duration: null,
    });
  }
}

export default errorNotification;