import {
  useRef,
  useState,
  useEffect,
  useContext,
  useCallback,
} from 'react'
import { message, Modal } from 'antd';
import AppContext, { ActionTypes } from '../../../AppContext';
import adminAPI, { LoginProps } from '../../../api/admin';
import errorNotification from '../../commons/notification/errorNotification';

const useAdmin = () => {
  
  const {
    isLogin,
    hasCheckedLogin,
    adminInfo,
    appDispatch,
  } = useContext(AppContext);

  const _isUnmount = useRef<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const checkLogin = useCallback(
    (): void => {

      adminAPI.checkLogin()
        .then(({ data }) => {

          appDispatch({
            type: ActionTypes.CHECK_LOGIN,
            adminInfo: data,
          });

        }).catch(() => {

          appDispatch({
            type: ActionTypes.CHECK_LOGIN,
            adminInfo: null,
          });
        });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const login = useCallback(
    (props: LoginProps): void => {

      if (isLoading || isLogin) {
        return;
      }

      setIsLoading(true);

      adminAPI.login(props)
        .then(({ data }) => {

          if (!_isUnmount.current) {
            setIsLoading(false);
          }
          
          appDispatch({
            type: ActionTypes.LOGIN,
            adminInfo: data,
          });

          message.success('登入成功!');
        }).catch(error => {

          if (!_isUnmount.current) {
            setIsLoading(false);
          }

          errorNotification('登入失敗', error);
        });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      isLogin,
      isLoading
    ]
  );

  const logout = useCallback(
    (): void => {

      if (isLoading || !isLogin) {
        return;
      }

      Modal.confirm({
        title: '確定登出？',
        // content: 'When clicked the OK button, this dialog will be closed after 1 second',
        async onOk() {

          setIsLoading(true);

          try {

            await adminAPI.logout();
            if (!_isUnmount.current) {
              setIsLoading(false);
            }

            appDispatch({ type: ActionTypes.LOGOUT });
            message.success('已成功登出!');
          } catch (error) {

            if (!_isUnmount.current) {
              setIsLoading(false);
            }

            errorNotification('登出失敗，請稍後嘗試');
          }
        },
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      isLogin,
      isLoading
    ]
  );

  useEffect(() => {
    return () => {
      _isUnmount.current = true;
    };
  }, []);

  return {
    isLogin,
    checkLogin,
    hasCheckedLogin,

    isLoading,
    adminInfo,
    login,
    logout,
  };
};

export default useAdmin;
