import { createContext, Dispatch } from 'react';
import { Admin } from './components/admin/@types/admin.d';

/**
 * Action Type
 */
export enum ActionTypes {
  CHECK_LOGIN = 'checkLogin',
  LOGIN = 'login',
  LOGOUT = 'logout',
}

export const appInitState = {
  isLogin: false,
  hasCheckedLogin: false,
  adminInfo: null,
  appDispatch: () => {},
};

type AdminInfo = Admin | null;

type CheckLoginAction = { type: ActionTypes.CHECK_LOGIN, adminInfo: AdminInfo };
type LoginAction = { type: ActionTypes.LOGIN, adminInfo: AdminInfo };
type LogoutAction = { type: ActionTypes.LOGOUT };

type Action = 
  | CheckLoginAction
  | LoginAction
  | LogoutAction;

interface State {
  isLogin: boolean,
  hasCheckedLogin: boolean,
  adminInfo: AdminInfo,
  appDispatch: Dispatch<Action> | (() => void),
}

/**
 * Reducers
 */
export function appReducer(state: State, action: Action): State {
  switch(action.type) {
    case ActionTypes.CHECK_LOGIN:
      return {
        ...state,
        isLogin: !!action.adminInfo,
        hasCheckedLogin: true,
        adminInfo: action.adminInfo ?? null,
      };
    case ActionTypes.LOGIN:
      return {
        ...state,
        isLogin: true,
        adminInfo: action.adminInfo,
      };
    case ActionTypes.LOGOUT:
      return {
        ...appInitState,
        hasCheckedLogin: true,
      };
    default:
      return state;
  }
}

/**
 * Context
 */
const AppContext = createContext<State>(appInitState);

export default AppContext;