import { memo } from 'react';
import { Spin } from 'antd';
import styled from 'styled-components';

import { GlobalStyle } from 'stylecomponents';

const Container = styled.div`
	position: relative;
	width: 100%;	
	min-height: 500px;
	display: flex;
	align-items: center;
	justify-content: center;
`;

const CupoyRouterLazyLoadingView = () => (
	<Container>
		<GlobalStyle.LoadingSpinCenter>
			<Spin />
		</GlobalStyle.LoadingSpinCenter>
	</Container>
);

export default memo(CupoyRouterLazyLoadingView, () => true);