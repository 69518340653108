import { Suspense, useEffect } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { Alert } from 'antd';

import { RoutePaths } from './@types/routes.d';
import routes from './routes';
import useAdmin from '../admin/hooks/useAdmin';

import CupoyRouterLazyLoadingView from './CupoyRouterLazyLoadingView';
import HomeView from '../HomeView';
import LoginView from '../admin/LoginView';

/*--------------------------
    Main Component
--------------------------*/
const RouteSwitch = () => {

  const {
    isLogin,
    adminInfo,
    checkLogin,
    hasCheckedLogin,
  } = useAdmin();

  useEffect(() => {
    checkLogin();
  }, [checkLogin]);

  if (!hasCheckedLogin) {
    return <CupoyRouterLazyLoadingView />;
  }

  return (
    <Switch>

      <Route
        path={RoutePaths.LOGIN}
        render={() => isLogin
          ? <Redirect to={RoutePaths.HOME} />
          : <LoginView />
        }
        exact
      />

      <Route
        path={RoutePaths.HOME}
        render={() => isLogin
          ? 
          <HomeView>
            <Suspense fallback={<CupoyRouterLazyLoadingView />}>
              {routes.map(route => (
                  <Route
                    key={route.path as string}
                    path={route.path}
                    render={props => {

                      const { privilege } = route;

                      const hadPrivilege = privilege
                        ? adminInfo?.privileges.includes(+privilege)
                        : true;
                      //console.log('%c privilege', 'background: red; color: white', adminInfo);

                      if (!hadPrivilege) {
                        return (
                          <Alert
                            type='error'
                            message='沒有權限'
                            description='你的管理員權限不足，無法查看此頁面。'
                            showIcon
                          />
                        );
                      }
                      
                      return <route.lazyComponent {...props} />;
                    }}
                  />
                )
              )}
            </Suspense>
          </HomeView>
          :
          <Redirect to={RoutePaths.LOGIN} />
        }
      />
      
      <Route path='*'>
        <Redirect to={RoutePaths.HOME} />
      </Route>
    </Switch>
  );
};

export default RouteSwitch;
