import { lazy } from 'react';
import { RoutePaths, RouteProps, RouteActionProps } from './@types/routes.d';
import { Privileges } from '../privilege/@types/privilege.d';

const BulletinListView = lazy(() => import('../bulletin/BulletinListView'));
const BulletinEditorView = lazy(() => import('../bulletin/BulletinEditorView'));

const ADNavView = lazy(() => import('../ad/ADNavView'));

const CustomerCaseNavView = lazy(() => import('../customer/CustomerCaseNavView'));
const CustomerCaseEditorView = lazy(() => import('../customer/CustomerCaseEditorView'));
const CustomerCaseHistoryView = lazy(() => import('../customer/CustomerCaseHistoryView'));

const LotteryListView = lazy(() => import('../lottery/LotteryListView'));
const LotteryContentView = lazy(() => import('../lottery/LotteryContentView'));
const LotteryEditorView = lazy(() => import('../lottery/LotteryEditorView'));

const ProfitEventListView = lazy(() => import('../profit-event/ProfitEventListView'));
const ProfitEventEditorView = lazy(() => import('../profit-event/ProfitEventEditorView'));

const GameNavView = lazy(() => import('../game/GameNavView'));
const AddGameView = lazy(() => import('../game/AddGameView'));
const GameMemberBlackListView = lazy(() => import('../game/GameMemberBlackListView'));
const GameServerListView = lazy(() => import('../game/GameServerListView'));
const GameMappingListView = lazy(() => import('../game/GameMappingListView'));
const GameBulletinListView = lazy(() => import('../game-bulletin/GameBulletinListView'));
const GameBulletinEditorView = lazy(() => import('../game-bulletin/GameBulletinEditorView'));

const ProductNavView = lazy(() => import('../product/ProductNavView'));

const salesMonthlyReport = lazy(() => import('../salesMonthlyReport/SalesMonthlyReport_NavView'));
const VariousSalesAnalysis_Nav = lazy(() => import('../variousSalesAnalysis/VariousSalesAnalysis_Nav'));


const ProductEditorView = lazy(() => import('../product/ProductEditorView'));
const CardGameMappingEditorView = lazy(() => import('../card-game-mapping/CardGameMappingEditorView'));

const B2COrderListView = lazy(() => import('../order/B2COrderListView'));
const B2COrderBankTransferListView = lazy(() => import('../order/B2COrderBankTransferListView'));
const B2COrderContentView = lazy(() => import('../order/B2COrderContentView'));
const B2COrderBankTransferContentView = lazy(() => import('../order/B2COrderBankTransferContentView'));

const BonusOrderListView = lazy(() => import('../order/BonusOrderListView'));

const CardCartListView = lazy(() => import('../card/CardCartListView'));
const UpdateCardView = lazy(() => import('../card/UpdateCardView'));
const UploadCardView = lazy(() => import('../card/UploadCardView'));
const UploadCardFileView = lazy(() => import('../card/UploadCardFileView'));
const CardInventoryView = lazy(() => import('../card/CardInventoryView'));
const CardUploadHistoryView = lazy(() => import('../card/CardUploadHistoryView'));
const CardListView = lazy(() => import('../card/CardListView'));
const BatchListView = lazy(() => import('../card/BatchListView'));
const ReturnCardView = lazy(() => import('../card/ReturnCardView'));
const CardReturnHistoryView = lazy(() => import('../card/CardReturnHistoryView'));

const AgencyListView = lazy(() => import('../agency/AgencyListView'));
const AgencyEditorView = lazy(() => import('../agency/AgencyEditorView'));

const TradeLogNavView = lazy(() => import('../trade/TradeLogNavView'));

const ESunNavView = lazy(() => import("../esun/ESunNavView"));
// const ESunLogDetailView = lazy(() => import("../esun/ESunLogDetailView"));
const ESunEditLogDetailView = lazy(() => import("../esun/ESunEditLogDetailView"));

const IbonNavView = lazy(() => import('../ibon/IbonNavView'));
const IbonLogInfoView = lazy(() => import('../ibon/IbonLogInfoView'));
const IbonLogEditorView = lazy(() => import('../ibon/IbonLogEditorView'));
const ToIbonLogInfoView = lazy(() => import('../ibon/ToIbonLogInfoView'));

const FamiportNavView = lazy(() => import('../famiport/FamiportNavView'));
const FamiportLogEditorView = lazy(() => import('../famiport/FamiportLogEditorView'));
const FamiportLogInfoView = lazy(() => import('../famiport/FamiportLogInfoView'));

const HilifeNavView = lazy(() => import('../hilife/HilifeNavView'));
const HilifeLogInfoView = lazy(() => import('../hilife/HilifeLogInfoView'));
const HilifeLogEditorView = lazy(() => import('../hilife/HilifeLogEditorView'));

const AllPayNavView = lazy(() => import('../allpay/AllPayNavView'));
const AllPayLogEditorView = lazy(() => import('../allpay/AllPayLogEditorView'));


const InvoiceNavView = lazy(() => import('../invoice/InvoiceNavView'));
const InvoiceDetailView = lazy(() => import('../invoice/InvoiceDetailView'));
const InvoiceAllowanceDetailView = lazy(() => import('../invoice/InvoiceAllowanceDetailView'));

const RefundNavView = lazy(() => import('../refund/RefundNavView'));

const ECPayNavView = lazy(() => import('../ecpay/ECPayNavView'));
const ECPayLogEditorView = lazy(() => import('../ecpay/ECPayLogEditorView'));
const ECPayLogInfoView = lazy(() => import('../ecpay/ECPayLogInfoView'));

const CathyAtmNavView = lazy(() => import('../cathyatm/CathyAtmNavView'));
const CathyAtmLogInfoView = lazy(() => import('../cathyatm/CathyAtmLogInfoView'));
const CathyAtmLogEditorView = lazy(() => import('../cathyatm/CathyAtmLogEditorView'));

const BonusListView = lazy(() => import('../bonus/BonusListView'));
const BonusLogsView = lazy(() => import('../bonus/BonusLogsView'));
const BonusManualView = lazy(() => import('../bonus/BonusManualView'));
const BonusMallView = lazy(() => import('../bonus/BonusMallView'));
const BonusRemainView = lazy(() => import('../bonus/BonusRemainView'));
const BonusCardView = lazy(() => import('../bonus/BonusCardView'));
const BonusCardInfoView = lazy(() => import('../bonus/BonusCardInfoView'));
const BonusCardGeneratorView = lazy(() => import('../bonus/BonusCardGeneratorView'));

const MemberListView = lazy(() => import('../member/MemberListView'));
const MemberEditorView = lazy(() => import('../member/MemberEditorView'));
const MemberInvoiceHistoryView = lazy(() => import('../member/MemberInvoiceHistoryView'));
const MemberContactModifyHistoryView = lazy(() => import('../member/MemberContactModifyHistoryView'));
const MemberLoginHistoryView = lazy(() => import('../member/MemberLoginHistoryView'));
const MemberBlockMobilephoneView = lazy(() => import('../member/MemberBlockMobilephoneView'));
const MemberBlockIpView = lazy(() => import('../member/MemberBlockIpView'));
const MemberBlockAccountView = lazy(() => import('../member/MemberBlockAccountView'));
const MemberPaymentReportView = lazy(() => import('../member/MemberPaymentReportView'));
const MemberMsgView = lazy(() => import('../member/MemberMsgView'));

const SystemMsgView = lazy(() => import('../system-msg/SystemMsgView'));
const SystemMsgEditorView = lazy(() => import('../system-msg/SystemMsgEditorView'));

const AdminListView = lazy(() => import('../admin/AdminListView'));
const AdminEditorView = lazy(() => import('../admin/AdminEditorView'));

const PrivilegeGrpListView = lazy(() => import('../privilege/PrivilegeGrpListView'));
const PrivilegeGrpEditorView = lazy(() => import('../privilege/PrivilegeGrpEditorView'));

const MaintainSystemView = lazy(() => import('../system/MaintainSystemView'));

export const getRouteData = (props: RouteActionProps): {
  pathname: string,
  search?: string,
  hash?: string,
  state?: { [propName: string]: any },
} => {

  const {
    path,
    params,
    search,
    hash,
    state,
  } = props;

  const searchString: string | undefined = search
    ? Object.keys(search)
      .reduce((acc, key, index) => {

        if (!search[key]) {
          return acc;
        }

        if (index === 0) {
          acc += '?';
        } else {
          acc += '&';
        }

        acc += `${key}=${search[key]}`;

        return acc;
      }, '')
    : void 0;

  return {
    pathname: params
      ? Object.keys(params).reduce((acc, key) => {

        const match = `:${key}`;

        if (acc.includes(match)) {
          return acc.replace(match, `${params[key]}`);
        }

        return acc;
      }, path)
      : path,
    search: searchString,
    hash: hash ? '#hash' : void 0,
    state,
  };
};

const routes: ReadonlyArray<RouteProps> = [

  {
    path: RoutePaths.BULLETIN,
    lazyComponent: BulletinListView,
    privilege: Privileges['站台公告管理'],
    exact: true,
  },
  {
    path: RoutePaths.BULLETIN_EDITOR,
    lazyComponent: BulletinEditorView,
    privilege: Privileges['新增/編輯公告'],
    exact: true,
  },
  {
    path: RoutePaths.AD,
    lazyComponent: ADNavView,
    privilege: Privileges['廣告管理'],
    exact: true,
  },
  {
    path: RoutePaths.CUSTOMER_CASE,
    lazyComponent: CustomerCaseNavView,
    privilege: Privileges['客訴服務管理'],
    exact: true,
  },
  {
    path: RoutePaths.CUSTOMER_CASEE_EDITOR,
    lazyComponent: CustomerCaseEditorView,
    privilege: Privileges['客訴管理修改'],
    exact: true,
  },
  {
    path: RoutePaths.CUSTOMER_CASE_HISTORY,
    lazyComponent: CustomerCaseHistoryView,
    privilege: Privileges['客訴服務管理'],
    exact: true,
  },
  {
    path: RoutePaths.LOTTERY,
    lazyComponent: LotteryListView,
    privilege: Privileges['抽獎管理'],
    exact: true,
  },
  {
    path: RoutePaths.LOTTERY_CONTENT,
    lazyComponent: LotteryContentView,
    privilege: Privileges['抽獎管理'],
    exact: true,
  },
  {
    path: RoutePaths.LOTTERY_EDITOR,
    lazyComponent: LotteryEditorView,
    privilege: Privileges['抽獎異動'],
    exact: true,
  },
  {
    path: RoutePaths.PROFIT_EVENT,
    lazyComponent: ProfitEventListView,
    privilege: Privileges['好康活動管理'],
    exact: true,
  },
  {
    path: RoutePaths.PROFIT_EVENT_EDITOR,
    lazyComponent: ProfitEventEditorView,
    privilege: Privileges['好康活動異動'],
    exact: true,
  },
  {
    path: RoutePaths.GAME_LIST,
    lazyComponent: GameNavView,
    privilege: Privileges['遊戲伺服器管理'],
    exact: true,
  },
  {
    path: RoutePaths.UPDATE_GAME,
    lazyComponent: AddGameView,
    privilege: Privileges['遊戲伺服器管理'],
    exact: true,
  },
  {
    path: RoutePaths.GAME_MEMBER_BLACK_LIST,
    lazyComponent: GameMemberBlackListView,
    privilege: Privileges['遊戲伺服器管理'],
    exact: true,
  },
  {
    path: RoutePaths.GAME_SERVER_LIST,
    lazyComponent: GameServerListView,
    privilege: Privileges['遊戲伺服器管理'],
    exact: true,
  },
  {
    path: RoutePaths.GAME_MAPPING_LIST,
    lazyComponent: GameMappingListView,
    privilege: Privileges['遊戲伺服器管理'],
    exact: true,
  },
  {
    path: RoutePaths.GAME_BULLETIN,
    lazyComponent: GameBulletinListView,
    exact: true,
  },
  {
    path: RoutePaths.GAME_BULLETIN_EDITOR,
    lazyComponent: GameBulletinEditorView,
    exact: true,
  },
  {
    path: RoutePaths.PRODUCT,
    lazyComponent: ProductNavView,
    privilege: Privileges['點卡商品管理'],
    exact: true,
  },

  {
    path: RoutePaths.SALESMONTHLYREPORT,
    lazyComponent: salesMonthlyReport,
    privilege: Privileges['salesMonthlyReport'],
    exact: true,
  },
  {
    path: RoutePaths.VARIOUSSALESANALYSIS,
    lazyComponent: VariousSalesAnalysis_Nav,
    privilege: Privileges['variousSalesAnalysis'],
    exact: true,
  },
  {
    path: RoutePaths.PRODUCT_EDITOR,
    lazyComponent: ProductEditorView,
    privilege: Privileges['建立/編輯商品'],
    exact: true,
  },
  {
    path: RoutePaths.PRODUCT_MAPPING_EDITOR,
    lazyComponent: CardGameMappingEditorView,
    privilege: Privileges['建立/編輯商品'],
    exact: true,
  },
  {
    path: RoutePaths.B2C_ORDER,
    lazyComponent: B2COrderListView,
    privilege: Privileges['點卡訂單查詢'],
    exact: true,
  },
  {
    path: RoutePaths.B2C_ORDER_BANK_TRANSFER,
    lazyComponent: B2COrderBankTransferListView,
    privilege: Privileges['點卡訂單查詢'],
    exact: true,
  },
  {
    path: RoutePaths.B2C_ORDER_CONTENT,
    lazyComponent: B2COrderContentView,
    privilege: Privileges['點卡訂單查詢'],
    exact: true,
  },
  {
    path: RoutePaths.B2C_ORDER_BANK_TRANSFER_CONTENT,
    lazyComponent: B2COrderBankTransferContentView,
    privilege: Privileges['點卡訂單查詢'],
    exact: true,
  },
  {
    path: RoutePaths.BONUS_ORDER,
    lazyComponent: BonusOrderListView,
    privilege: Privileges['點卡訂單查詢'],
    exact: true,
  },
  {
    path: RoutePaths.CARD_CART,
    lazyComponent: CardCartListView,
    privilege: Privileges['點卡訂單查詢'],
    exact: true,
  },
  {
    path: RoutePaths.UPDATE_CARD,
    lazyComponent: UpdateCardView,
    privilege: Privileges['點數卡管理'],
    exact: true,
  },
  {
    path: RoutePaths.UPLOAD_CARD,
    lazyComponent: UploadCardView,
    privilege: Privileges['點數卡管理'],
    exact: true,
  },
  {
    path: RoutePaths.UPLOAD_CARD_FILE,
    lazyComponent: UploadCardFileView,
    privilege: Privileges['點數卡管理'],
    exact: true,
  },
  {
    path: RoutePaths.CARD_INVENTORY,
    lazyComponent: CardInventoryView,
    privilege: Privileges['點數卡管理'],
    exact: true,
  },
  {
    path: RoutePaths.CARD_UPLOAD_HISTORY,
    lazyComponent: CardUploadHistoryView,
    privilege: Privileges['點數卡管理'],
    exact: true,
  },
  {
    path: RoutePaths.CARD,
    lazyComponent: CardListView,
    privilege: Privileges['點數卡管理'],
    exact: true,
  },
  {
    path: RoutePaths.BATCH,
    lazyComponent: BatchListView,
    privilege: Privileges['點數卡管理'],
    exact: true,
  },
  {
    path: RoutePaths.RETURN_CARD,
    lazyComponent: ReturnCardView,
    privilege: Privileges['點數卡管理'],
    exact: true,
  },
  {
    path: RoutePaths.CARD_RETURN_HISTORY, 
    lazyComponent: CardReturnHistoryView,
    privilege: Privileges['點數卡管理'],
    exact: true,
  },
  {
    path: RoutePaths.AGENCY,
    lazyComponent: AgencyListView,
    privilege: Privileges['廠商管理'],
  },
  {
    path: RoutePaths.AGENCY_EDITOR,
    lazyComponent: AgencyEditorView,
    privilege: Privileges['新增/編輯廠商'],
  },
  {
    path: RoutePaths.TRADE_LOG,
    lazyComponent: TradeLogNavView,
    privilege: Privileges['商品銷售紀錄'],
  },
  {
    path: RoutePaths.E_SUN,
    lazyComponent: ESunNavView,
    privilege: Privileges['玉山ATM資料管理'],
  },
  // {
  //   path: RoutePaths.E_SUN_LOG_DETAIL,
  //   lazyComponent: ESunLogDetailView,
  //   privilege: Privileges['玉山ATM資料管理'],
  // },
  {
    path: RoutePaths.E_SUN_EDIT_LOG_DETAIL,
    lazyComponent: ESunEditLogDetailView,
    privilege: Privileges['玉山ATM資料管理'],
  },
  {
    path: RoutePaths.IBON,
    lazyComponent: IbonNavView,
    privilege: Privileges['IBon繳費記錄查詢'],
  },
  {
    path: RoutePaths.IBON_LOG_INFO,
    lazyComponent: IbonLogInfoView,
    privilege: Privileges['IBon繳費記錄查詢'],
  },
  {
    path: RoutePaths.IBON_LOG_EDITOR,
    lazyComponent: IbonLogEditorView,
    privilege: Privileges['IBon人工入帳'],
  },
  {
    path: RoutePaths.TO_IBON_LOG_INFO,
    lazyComponent: ToIbonLogInfoView,
    privilege: Privileges['IBon人工入帳'],
  },
  {
    path: RoutePaths.FAMIPORT,
    lazyComponent: FamiportNavView,
    privilege: Privileges['全家繳款記錄查詢'],
  },
  {
    path: RoutePaths.FAMIPORT_LOG_INFO,
    lazyComponent: FamiportLogInfoView,
    privilege: Privileges['全家繳款記錄查詢'],
  },
  {
    path: RoutePaths.FAMIPORT_LOG_EDITOR,
    lazyComponent: FamiportLogEditorView,
    privilege: Privileges['全家繳款記錄查詢'],
  },
  {
    path: RoutePaths.HILIFE,
    lazyComponent: HilifeNavView,
    privilege: Privileges['查詢萊爾富紀錄'],
  },
  {
    path: RoutePaths.HILIFE_LOG_INFO,
    lazyComponent: HilifeLogInfoView,
    privilege: Privileges['查詢萊爾富紀錄'],
  },
  {
    path: RoutePaths.HILIFE_LOG_EDITOR,
    lazyComponent: HilifeLogEditorView,
    privilege: Privileges['查詢萊爾富紀錄'],
  },
  {
    path: RoutePaths.ALLPAY,
    lazyComponent: AllPayNavView,
    privilege: Privileges['信用卡記錄查詢'],
  },
  {
    path: RoutePaths.ALLPAY_LOG_EDITOR,
    lazyComponent: AllPayLogEditorView,
    privilege: Privileges['信用卡記錄查詢'],
  },
  {
    path: RoutePaths.INVOICE,
    lazyComponent: InvoiceNavView,
    privilege: Privileges['發票管理'],
  },
  {
    path: RoutePaths.INVOICE_DETAIL,
    lazyComponent: InvoiceDetailView,
    privilege: Privileges['發票管理'],
  },
  {
    path: RoutePaths.INVOICE_ALLOWANCE_DETAIL,
    lazyComponent: InvoiceAllowanceDetailView,
    privilege: Privileges['發票管理'],
  },
  {
    path: RoutePaths.REFUND,
    lazyComponent: RefundNavView,
    privilege: Privileges['提款轉帳管理'],
  },
  {
    path: RoutePaths.ECPAY,
    lazyComponent: ECPayNavView,
    privilege: Privileges['綠界ATM管理'],
  },
  {
    path: RoutePaths.ECPAY_LOG_EDITOR,
    lazyComponent: ECPayLogEditorView,
    privilege: Privileges['綠界ATM異動'],
  },
  {
    path: RoutePaths.ECPAY_LOG_INFO,
    lazyComponent: ECPayLogInfoView,
    privilege: Privileges['綠界ATM管理'],
  },
  {
    path: RoutePaths.CATHY,
    lazyComponent: CathyAtmNavView,
    privilege: Privileges['國泰世華ATM查詢'],
  },
  {
    path: RoutePaths.CATHY_LOG_INFO,
    lazyComponent: CathyAtmLogInfoView,
    privilege: Privileges['國泰世華ATM查詢'],
  },
  {
    path: RoutePaths.CATHY_LOG_EDITOR,
    lazyComponent: CathyAtmLogEditorView,
    privilege: Privileges['國泰世華ATM異動'],
  },
  {
    path: RoutePaths.BONUS,
    lazyComponent: BonusListView,
    privilege: Privileges['發財金管理'],
  },
  {
    path: RoutePaths.BONUS_LOG,
    lazyComponent: BonusLogsView,
    privilege: Privileges['發財金管理'],
  },
  {
    path: RoutePaths.BONUS_MANUAL,
    lazyComponent: BonusManualView,
    privilege: Privileges['發財金異動'],
  },
  {
    path: RoutePaths.BONUS_MALL,
    lazyComponent: BonusMallView,
    privilege: Privileges['發財金異動'],
  },
  {
    path: RoutePaths.BONUS_REMAIN,
    lazyComponent: BonusRemainView,
    privilege: Privileges['發財金管理'],
  },
  {
    path: RoutePaths.BONUS_CARD,
    lazyComponent: BonusCardView,
    privilege: Privileges['發財金管理'],
  },
  {
    path: RoutePaths.BONUS_CARD_INFO,
    lazyComponent: BonusCardInfoView,
    privilege: Privileges['發財金管理'],
  },
  {
    path: RoutePaths.BONUS_CARD_GENERATOR,
    lazyComponent: BonusCardGeneratorView,
    privilege: Privileges['發財金異動'],
  },
  {
    path: RoutePaths.MEMBER,
    lazyComponent: MemberListView,
    privilege: Privileges['B2C會員管理'],
    exact: true,
  },
  {
    path: RoutePaths.MEMBER_INFO,
    lazyComponent: MemberEditorView,
    privilege: Privileges['編輯會員管理'],
    exact: true,
  },
  {
    path: RoutePaths.MEMBER_INVOICE_HISTORY,
    lazyComponent: MemberInvoiceHistoryView,
    privilege: Privileges['B2C會員管理'],
    exact: true,
  },
  {
    path: RoutePaths.MEMBER_CONTACT_MODIFY_HISTORY,
    lazyComponent: MemberContactModifyHistoryView,
    privilege: Privileges['B2C會員管理'],
    exact: true,
  },
  {
    path: RoutePaths.MEMBER_LOGIN_HISTORY,
    lazyComponent: MemberLoginHistoryView,
    privilege: Privileges['B2C會員管理'],
    exact: true,
  },
  {
    path: RoutePaths.MEMBER_BLOCK_MOBILEPHONE,
    lazyComponent: MemberBlockMobilephoneView,
    privilege: Privileges['B2C會員管理'],
    exact: true,
  },
  {
    path: RoutePaths.MEMBER_BLOCK_IP,
    lazyComponent: MemberBlockIpView,
    privilege: Privileges['B2C會員管理'],
    exact: true,
  },
  {
    path: RoutePaths.MEMBER_BLOCK_ACCOUNT,
    lazyComponent: MemberBlockAccountView,
    privilege: Privileges['B2C會員管理'],
    exact: true,
  },
  {
    path: RoutePaths.MEMBER_PAYMENT_REPORT,
    lazyComponent: MemberPaymentReportView,
    privilege: Privileges['B2C會員管理'],
    exact: true,
  },
  {
    path: RoutePaths.MEMBER_MSG,
    lazyComponent: MemberMsgView,
    privilege: Privileges['B2C會員管理'],
    exact: true,
  },
  {
    path: RoutePaths.SYSTEM_MSG,
    lazyComponent: SystemMsgView,
    privilege: Privileges['B2C會員管理'],
    exact: true,
  },
  {
    path: RoutePaths.SYSTEM_MSG_EDITOR,
    lazyComponent: SystemMsgEditorView,
    privilege: Privileges['B2C會員管理'],
    exact: true,
  },
  {
    path: RoutePaths.ADMIN,
    lazyComponent: AdminListView,
    privilege: Privileges['站台管理室'],
    exact: true,
  },
  {
    path: RoutePaths.ADMIN_EDITOR,
    lazyComponent: AdminEditorView,
    privilege: Privileges['編輯站台管理室'],
    exact: true,
  },
  {
    path: RoutePaths.PRIVILEGE_GRP,
    lazyComponent: PrivilegeGrpListView,
    privilege: Privileges['權限群組管理'],
    exact: true,
  },
  {
    path: RoutePaths.PRIVILEGE_GRP_EDITOR,
    lazyComponent: PrivilegeGrpEditorView,
    privilege: Privileges['建立/編輯權限群組'],
    exact: true,
  },
  {
    path: RoutePaths.MAINTAIN_SYSTEM,
    lazyComponent: MaintainSystemView,
    privilege: Privileges['重啟各項服務'],
    exact: true,
  }
];

export default routes;
