import { memo } from 'react';
import { Layout, Dropdown, Menu } from 'antd';
import { UserOutlined, LoadingOutlined } from '@ant-design/icons';
import styled from 'styled-components';

import useAdmin from '../admin/hooks/useAdmin';
import { Colors } from 'stylecomponents';

/**
 * Styled
 */
const HeaderContent = styled.div`
  display: grid;
  justify-content: flex-end;
  align-items: center;
  height: 100%;
`;
const AdminInfo = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  font-size: 18px;
  font-weight: 500;
  line-height: 1.5em;
  color: #FFF;
  user-select: none;
  cursor: pointer;
`;

/**
 * 首頁 TopBar
 * @author Ac
 * @since 2021 / 04 / 19
 */
const TopBar = () => {

  const {
    adminInfo,
    logout,
    isLoading,
  } = useAdmin();

  if (!adminInfo) {
    return null;
  }

  const { name } = adminInfo;

  return (
    <Layout.Header>
      <HeaderContent>
        <Dropdown
          arrow
          overlay={
            <Menu>
              <Menu.Item onClick={logout}>
                {isLoading && <><LoadingOutlined style={{ color: Colors.MAIN_COLOR }} />&nbsp;</>}
                登出管理員
              </Menu.Item>
            </Menu>
          }
        >
          <AdminInfo>
            <UserOutlined style={{ marginRight: 8 }} />
            {name}
          </AdminInfo>
        </Dropdown>
      </HeaderContent>
    </Layout.Header>
  )
};

export default memo(TopBar, () => true);
