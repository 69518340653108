import { useRef, useState, useCallback } from 'react';
import styled from 'styled-components';
import { Redirect } from 'react-router-dom';
import { UploadOutlined } from '@ant-design/icons';
import Recaptcha from 'react-grecaptcha';
import {
  Form,
  Input,
  Upload,
  Button,
  Image,
  notification,
} from 'antd';

import GLOBAL from 'utils/Global';
import { RoutePaths } from '../router/@types/routes.d';
import useAdmin from './hooks/useAdmin';

/**
 * Styled
 */
const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: -webkit-fill-available;
  min-height: 100vh;
  background-image: url("/images/login-bg.svg");
  background-repeat: no-repeat;
  background-size: contain;
`;
const Content = styled.div`
  padding: 40px;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 384px;
  margin: 0 auto;
`;
const Logo = styled(Image).attrs(() => ({
  src: '/images/logo.png',
  preview: false,
}))`
  margin: 40px auto;
`;
const Footer = styled.footer`
  padding: 40px 20px;
  text-align: center;
`;

/**
 * 登入頁面
 * @author Ac
 * @since 2021 / 04 / 16
 */
const LoginView = () => {

  const {
    isLogin,
    isLoading,
    login,
    // logout,
  } = useAdmin();

  const year = useRef<number>(new Date().getFullYear()).current;
  const [form] = Form.useForm();
  const [authFile, setAuthFile] = useState<File | null>(null);

  const handleAuthFile = useCallback(
    (file: File): boolean => {

      const isLt1M = file.size / 1024 / 1024 > 1;
      if (isLt1M) {
        notification.warning({
          message: '檔案過大',
          description: '上傳金鑰檔案不可超過1MB',
        });
        return false;
      }

      setAuthFile(file);

      // 讓表單去判斷是否有值，沒有要跳提醒
      form.setFieldsValue({
        __authFile: file,
      });

      // return false 手動上傳
      return false;
    },
    [form]
  );

  const handleSubmit = useCallback(
    (): void => {
      form.submit();
    },
    [form]
  );

  const handlePasscode = useCallback(
    (token: string): void => {
      form.setFieldsValue({
        passcode: token,
      });
    },
    [form]
  );

  const handleRecaptchaExpired = useCallback(
    (): void => {
      form.setFieldsValue({
        passcode: null,
      });
    },
    [form]
  );

  const handleFinish = useCallback(
    (
      {
        adminId,
        passwd,
        passcode,
      }: {
        adminId: string,
        passwd: string,
        passcode: string,
      }
    ): void => {

      if (!authFile) {
        notification.warning({
          message: '請上傳金鑰檔',
          description: '您沒有上傳管理員金鑰檔',
        });
        return;
      }

      login({
        adminId,
        passwd,
        passcode,
        authFile,
      });
    },
    [login, authFile]
  );

  if (isLogin) {
    return <Redirect to={RoutePaths.HOME} />;
  }

  return (
    <Container>

      <Content>

        <Logo width={210} />

        <Form
          form={form}
          layout="vertical"
          style={{ width: '100%' }}
          requiredMark={false}
          onFinish={handleFinish}
        >
          <Form.Item
            label="管理者帳戶"
            name="adminId"
            rules={[{ required: true, whitespace: true, message: '請輸入您的管理者帳號!' }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="管理者密碼"
            name="passwd"
            rules={[{ required: true, whitespace: true, message: '請輸入您的管理者密碼!' }]}
          >
            <Input type='password' />
          </Form.Item>

          <Form.Item
            label="登入驗證"
            name="passcode"
            rules={[{ required: true, whitespace: true, message: '請勾選「我不是機器人」!' }]}
          >
            <Recaptcha
              sitekey={GLOBAL.GRECAPTCHA_KEY}
              callback={handlePasscode}
              expiredCallback={handleRecaptchaExpired}
              locale="zh-TW"
            />
          </Form.Item>

          <Form.Item
            label="管理者金鑰"
            name="__authFile"
            rules={[{ required: true, whitespace: true, message: '請上傳管理員金鑰檔案!' }]}
          >
            <div>
              <Upload
                accept='.key'
                maxCount={1}
                beforeUpload={handleAuthFile}
              >
                <Button icon={<UploadOutlined />}>上傳金鑰檔</Button>
              </Upload>
            </div>
          </Form.Item>
        </Form>

        <Button
          type='primary'
          onClick={handleSubmit}
          size='large'
          loading={isLoading}
          style={{ width: '100%', marginTop: 20 }}
        >
          登入
        </Button>
      </Content>

      <Footer>
        淞果數位股份有限公司Copyright©{year > 2021 ? `2021-${year}` : year} SoCool Digital CO.,LTD. All Rights Reserved.
        本站相關圖文版權均屬該原廠官方所有，如有相關問題歡迎來信告知，我們將立即為您處理！
      </Footer>
    </Container>
  );
};

export default LoginView;