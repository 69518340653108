
// value 指定為字串是因為需要 Object.key(Privileges) 這樣的code，如果是數字會沒辦法判斷 @ac
export enum Privileges {
  // '基本權限(初始值)' = '3',
  // '進入管理後端' = '5',
  // '權限列表' = '29',
  // '建立權限' = '47',
  // '留言版管理' = '331',
  // '修改留言版' = '337',
  // 'B2C退貨管理' = '281',
  // 'B2C異動退貨紀錄' = '283',
  // '退卡管理' = '157',
  // '虛擬帳戶管理' = '173',
  // '虛擬帳戶異動' = '179',
  // 'B2B發票管理' = '193',
  // 'B2B發票異動' = '197',
  // '更新/下載幣商金鑰' = '223',
  // '歐付寶異動' = '463',
  // '發送電子報' = '233',
  // '編輯電子報' = '239',
  // '更新/下載B2B會員金鑰' = '103',
  // 'B2B會員群組管理' = '107',
  // '編輯B2B會員群組' = '109',
  // 'B2B會員管理' = '113',
  // '編輯B2B會員' = '127',

  '站台公告管理' = '11',
  '新增/編輯公告' = '19',
  '廣告管理' = '43',
  '編輯廣告' = '23',
  '編輯會員管理' = '17',
  'B2C會員管理' = '67',
  '站台管理室' = '73',
  '編輯站台管理室' = '31',
  '權限群組管理' = '79',
  '建立/編輯權限群組' = '83',
  '重啟各項服務' = '503',
  '好康活動管理' = '449',
  '好康活動異動' = '457',
  '抽獎管理' = '439',
  '抽獎異動' = '443',
  '建立/編輯商品' = '41',
  '點卡商品管理' = '59',
  'salesMonthlyReport' = '199',
  'variousSalesAnalysis' = '199',
  '點卡訂單查詢' = '467',
  '點數卡管理' = '139',
  '商品銷售紀錄' = '199',
  '新增/編輯廠商' = '37',
  '廠商管理' = '71',
  '更新/下載管理員金鑰' = '101',
  '發財金管理' = '431',
  '發財金異動' = '433',
  'IBon繳費記錄查詢' = '419',
  'IBon人工入帳' = '421',
  '玉山ATM資料管理' = '163',
  '玉山ATM資料修改' = '167',
  '遊戲伺服器管理' = '7',
  '編輯遊戲伺服器資料' = '13',
  '管理對帳單' = '131',
  '編輯對帳單' = '137',
  '客訴服務管理' = '227',
  '客訴管理修改' = '229',
  '發票管理' = '293',
  '發票異動' = '307',
  '全家繳款記錄查詢' = '311',
  '提款轉帳管理' = '347',
  '查詢白名單' = '349',
  '異動白名單' = '353',
  '查詢萊爾富紀錄' = '359',
  '兆豐ATM資料管理' = '389',
  '編輯兆豐ATM' = '397',
  '全家EC繳款紀錄查詢' = '401',
  '發票補印下載' = '409',
  '信用卡記錄查詢' = '461',
  '綠界ATM管理' = '479',
  '綠界ATM異動' = '487',
  '國泰世華ATM查詢' = '491',
  '國泰世華ATM異動' = '499',
}

/**
 * 權限群組
 */
export interface PrivilegeGrp {
  // 群組 id
  readonly grpid: number,
  // 群組名稱
  readonly grpname: string,
  // 群組權限 ( 以 "_" 分隔 )，實際權限請看 enum Privileges
  readonly privilege: string,
  // 群組敘述
  readonly description: string,
}