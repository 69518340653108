import { useReducer } from 'react';
import RouteSwitch from './router/RouteSwitch';
import AppContext, { appReducer, appInitState } from '../AppContext';

const RootView = () => {

  const [appState, appDispatch] = useReducer(appReducer, appInitState);

  return (
    <AppContext.Provider
      value={{
        ...appState,
        appDispatch,
      }}
    >
      <RouteSwitch />
      {/* put dialog here... */}
    </AppContext.Provider>
  )
};

export default RootView;