
// Google Recaptcha Key
const GRECAPTCHA_KEY = '6LfJa3EUAAAAAEuCCizjmVAHzKAVP50Rn6yWCyrI';

const imgSrcRootURL =
  window.location.host.includes('inmy.design') || window.location.host.startsWith('localhost')
  ? 'https://img.inmy.design'
  : 'https://img.9199.com.tw';

/**
 * 檢查執行環境是否是development
 * @author Ac
 * @since 2021 / 03 / 16
 */
const checkIsDevEnv = () : boolean => !process.env.NODE_ENV || process.env.NODE_ENV === 'development';
const isDevelopment: boolean = checkIsDevEnv();
const PAYMENT_LIMIT_HOUR = 1;

function parseString2TXT(string: string) {
  return new Blob([string], { type: 'text/plain; charset=utf-8' });
}

// 將字串轉換為 csv 二進位檔
function parseString2CSV(string: string) {
   return new Blob(['\ufeff'+ string], { type: 'text/csv' });
}

function parseString2Excel(base64Data: string, contentType: string) {
  contentType = contentType || '';
  let sliceSize = 1024;
  let byteCharacters = atob(base64Data);
  let bytesLength = byteCharacters.length;
  let slicesCount = Math.ceil(bytesLength / sliceSize);
  let byteArrays = new Array(slicesCount);

  for (let sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
    let begin = sliceIndex * sliceSize;
    let end = Math.min(begin + sliceSize, bytesLength);

    let bytes = new Array(end - begin);
    for (let offset = begin, i = 0; offset < end; ++i, ++offset) {
        bytes[i] = byteCharacters[offset].charCodeAt(0);
    }
    byteArrays[sliceIndex] = new Uint8Array(bytes);
  }
  return new Blob(byteArrays, { type: contentType });
}

function renderWrapContent(string: string) {
  
  let arr: string[] = [];

  if (string) {
    if (string.indexOf('<br>') >= 0) {
      arr = string.split('<br>');
    } else if (string.indexOf('\n') >= 0) {
      arr = string.split('\n');
    } else {
      arr = [string];
    }
  }
  
  return arr;
}

function utoa(str : string) {
  return btoa(unescape(encodeURIComponent(str)))
}

const yearSelectOptions = new Array((new Date().getFullYear() - 1911) - 97)
  .fill(null)
  .map((_, index) =>
    (index + 98)
      .toString()
      .padStart(3, '0')
);

const monthSelectOptions = new Array(12)
  .fill(null)
  .map((_, index) =>
    (index + 1)
      .toString()
      .padStart(2, '0')
);

function handleTableClassName(_record: any, index: number): string {
  let finalClass = ""
  if (index % 2 === 0) {
    finalClass = "antd_table__row__bg-blue"
  }
  
  return finalClass;
}

const Global = {
  isDevelopment, // 是否在開發環境
  imgSrcRootURL, // 圖片來源網址
  PAYMENT_LIMIT_HOUR,//訂單逾期時間(小時)
  GRECAPTCHA_KEY, // Google Recaptcha Key
  /**
   * 將字串轉換為 csv 二進位檔
   * @description 將字串轉換為 csv 二進位檔並添加 BOM ( 9199 使用 excel 開啟，未添加 BOM 會導致亂碼)
   */
  parseString2TXT,
  parseString2CSV,
  parseString2Excel,

  renderWrapContent,
  utoa, // base64編碼(可使用中文)

  yearSelectOptions, // 民國年
  monthSelectOptions, // 月份
  handleTableClassName,
};

export default Global;