/*--------------------------
    Style Variables
--------------------------*/
const MAIN_COLOR = '#F75C2F';
const MAIN_COLOR_LIGHT = '#FF8559';
const Red = '#F14342';
const Yellow = '#EEE552';
const Orange = '#F19840';
const Blue = '#2E7AC3';
const BlueLight = '#14A5FF';
const Green = '#008675';
const DarkGreen = '#009688';
const Purple = '#443AB7';

const Success = '#008675';
const Error = '#F14342';

const Dark = '#000';
const Dark2 = '#333333';
const Dark3 = '#4A4A4A';
const Dark4 = '#6a6a6a';
const Dark5 = '#9B9B9B';

const Title = '#333030';
const Paragraph = '#565151';
const LightText = '#A79E9D';
const Text = '#565151';

const ImgBackground = '#DDD';

const Colors = {
  MAIN_COLOR,
  MAIN_COLOR_LIGHT,
  Red,
  Yellow,
  Orange,
  Blue,
  BlueLight,
  Green,
  DarkGreen,
  Success,
  Error,
  Dark,
  Dark2,
  Dark3,
  Dark4,
  Dark5,
  Purple,

  Title,
  Paragraph,
  LightText,
  Text,
  ImgBackground,
}

export default Colors;