import { ReactNode } from 'react';
import { Layout } from 'antd';
import styled from 'styled-components';

import TopBar from './TopBar';
import SideBar from './SideBar';
import Footer from './Footer';

/**
 * Styled
 */
const HomeContainer = styled(Layout)`
  min-height: 100vh !important;
`;
const HomeContent = styled(Layout.Content)`
  margin: 16px 0;
  padding: 0 24px;
`;

/**
 * 首頁
 * @author Ac
 * @since 2021 / 04 / 19
 */
const HomeView = ({ children }: { children: ReactNode }) => {
  return (
    <HomeContainer>

      <SideBar />

      <Layout>
        
        <TopBar />
        
        <HomeContent>
          {children}
        </HomeContent>
  
        <Footer />
      </Layout>
    </HomeContainer>
  );
};

export default HomeView;