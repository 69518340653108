import { memo, useRef, useState, useCallback, useEffect } from 'react';
import { Layout, Menu, Image } from 'antd';
import styled from 'styled-components';
import useAdmin from '../admin/hooks/useAdmin';
import { Privileges } from '../privilege/@types/privilege.d';

import { RoutePaths } from '../router/@types/routes.d';

import RouteLink from '../router/RouteLink';

/**
 * Variables
 */
const { SubMenu } = Menu;


interface ISubList {
  title: string,
  path: RoutePaths,
  privilege?: string,
}

type SubList  = {
  [key: string]: ISubList[]
}

interface INav {
  readonly title: string,
  readonly subList: ISubList[]
}

const NAV_LIST: INav[] = [
  {
    title: '網站內容管理',
    subList: [
      {
        title: '站台公告管理',
        path: RoutePaths.BULLETIN,
        privilege: Privileges['站台公告管理'],
      },
      {
        title: '廣告管理',
        path: RoutePaths.AD,
        privilege: Privileges['廣告管理']
      },
      {
        title: '客訴服務管理',
        path: RoutePaths.CUSTOMER_CASE,
        privilege: Privileges['客訴服務管理']
      },
      {
        title: '抽獎管理',
        path: RoutePaths.LOTTERY,
        privilege: Privileges['抽獎管理'],
      },
      {
        title: '好康活動管理',
        path: RoutePaths.PROFIT_EVENT,
        privilege: Privileges['好康活動管理'],
      }
    ],
  },
  {
    title: '虛寶業務',
    subList: [
      {
        title: '遊戲伺服器管理',
        path: RoutePaths.GAME_LIST,
        privilege: Privileges['遊戲伺服器管理']
      },
      {
        title: '遊戲公告管理',
        path: RoutePaths.GAME_BULLETIN,
      }
    ],
  },
  {
    title: '點卡業務',
    subList: [
      {
        title: '點卡商品管理',
        path: RoutePaths.PRODUCT,
        privilege: Privileges['點卡商品管理']
      },
      /*
      {
        title: '商品銷售月報表',
        path: RoutePaths.SALESMONTHLYREPORT,
        privilege: Privileges['salesMonthlyReport'],
      },
      {
        title: '各類銷售分析表驗收',
        path: RoutePaths.VARIOUSSALESANALYSIS,
        privilege: Privileges['variousSalesAnalysis'],
      },
      */
      {
        title: '廠商管理',
        path: RoutePaths.AGENCY,
        privilege: Privileges['廠商管理']
      },
      {
        title: '商品銷售紀錄',
        path: RoutePaths.TRADE_LOG,
        privilege: Privileges['商品銷售紀錄']
      },
      {
        title: '上傳點卡',
        path: RoutePaths.UPLOAD_CARD,
        privilege: Privileges['點數卡管理']
      },
      {
        title: '點數卡庫存',
        path: RoutePaths.CARD_INVENTORY,
        privilege: Privileges['點數卡管理']
      },
      {
        title: '點卡上傳紀錄',
        path: RoutePaths.CARD_UPLOAD_HISTORY,
        privilege: Privileges['點數卡管理']
      },
      {
        title: '批號管理',
        path: RoutePaths.BATCH,
        privilege: Privileges['點數卡管理']
      },
      {
        title: 'B2C 訂單查詢',
        path: RoutePaths.B2C_ORDER,
        privilege: Privileges['點卡訂單查詢']
      },
      {
        title: 'B2C 歷史訂單查詢',
        path: RoutePaths.B2C_ORDER_BANK_TRANSFER,
        privilege: Privileges['點卡訂單查詢']
      },
      {
        title: '發財金訂單查詢',
        path: RoutePaths.BONUS_ORDER,
        privilege: Privileges['點卡訂單查詢']
      },
      {
        title: '點數卡購物車查詢',
        path: RoutePaths.CARD_CART,
        privilege: Privileges['點卡訂單查詢']
      },
      {
        title: '查詢下架紀錄',
        path: RoutePaths.CARD_RETURN_HISTORY,
        privilege: Privileges['點數卡管理']
      },
    ],
  },
  {
    title: '金流管理',
    subList: [
      {
        title: '玉山 ATM 資料管理',
        path: RoutePaths.E_SUN,
        privilege: Privileges['玉山ATM資料管理']
      },
      {
        title: '發票管理',
        path: RoutePaths.INVOICE,
        privilege: Privileges['發票管理']
      },
      {
        title: '全家繳款紀錄查詢',
        path: RoutePaths.FAMIPORT,
        privilege: Privileges['全家繳款記錄查詢']
      },
      {
        title: '提款轉帳管理',
        path: RoutePaths.REFUND,
        privilege: Privileges['提款轉帳管理']
      },
      {
        title: '查詢萊爾富紀錄',
        path: RoutePaths.HILIFE,
        privilege: Privileges['查詢萊爾富紀錄']
      },
      {
        title: 'iBon 繳費管理',
        path: RoutePaths.IBON,
        privilege: Privileges['IBon繳費記錄查詢']
      },
      {
        title: '發財金管理',
        path: RoutePaths.BONUS,
        privilege: Privileges['發財金管理']
      },
      {
        title: '信用卡記錄查詢',
        path: RoutePaths.ALLPAY,
        privilege: Privileges['信用卡記錄查詢']
      },
      {
        title: '綠界ATM管理',
        path: RoutePaths.ECPAY,
        privilege: Privileges['綠界ATM管理']
      },
      {
        title: '國泰世華ATM管理',
        path: RoutePaths.CATHY,
        privilege: Privileges['國泰世華ATM查詢']
      },
    ],
  },
  {
    title: '一般會員管理',
    subList: [
      {
        title: 'B2C會員管理',
        path: RoutePaths.MEMBER,
        privilege: Privileges['B2C會員管理']
      }
    ],
  },
  {
    title: '站台管理',
    subList: [
      {
        title: '站台管理室',
        path: RoutePaths.ADMIN,
        privilege: Privileges['站台管理室']
      },
      {
        title: '權限群組管理',
        path: RoutePaths.PRIVILEGE_GRP,
        privilege: Privileges['權限群組管理']
      },
      {
        title: '重啟各項服務',
        path: RoutePaths.MAINTAIN_SYSTEM,
        privilege: Privileges['重啟各項服務']
      }
    ],
  }
];

/**
 * Styled
 */
const Logo = styled(Image)`
  width: calc(100% - 16px) !important;
  margin: 20px auto;
`;

/**
 * 首頁側邊欄 
 */
const SideBar = () => {

  const {
    adminInfo,
  } = useAdmin();

  const [navList, setNavList] = useState(NAV_LIST);

  useEffect(() => {

    if (!adminInfo) {
      return;
    }

    let tempMap: SubList = {};

    const _navList = NAV_LIST.filter(({ title, subList }) => {
      const _subList = subList.filter(({ title, path, privilege }) => {
        const hasPrivilege = privilege ? adminInfo?.privileges.includes(+privilege) : true;
        return hasPrivilege;
      })

      tempMap[title] = _subList;

      if (!_subList || _subList.length === 0) {
        return false;
      }
      return true;
    })

    if (tempMap) {
      _navList.map(({title}, index) => {
        
        let _nav = {
          title,
          subList: tempMap[title]
        }

        _navList[index] = _nav;
        return null;
      })
    }

    setNavList(_navList);
    
  }, [adminInfo])

  const defaultSelectedKeys = useRef<string[]>([window.location.pathname]).current;
  const defaultOpenMenuKeys = useRef<string[]>([
    NAV_LIST.find(({ subList }) => {
      return subList.find(({ path }) => path === defaultSelectedKeys[0]);
    })?.title ?? ''
  ]).current;

  const [collapsed, setCollapsed] = useState<boolean>(false);

  const onCollapse = useCallback(
    (collapsed: boolean): void => {
      setCollapsed(collapsed);
    },
    []
  );

  return (
    <Layout.Sider
      collapsible
      collapsed={collapsed}
      onCollapse={onCollapse}
    >
      <RouteLink path={RoutePaths.HOME}>
        <Logo src='/images/logo.png' preview={false} />
      </RouteLink>
      <Menu
        theme='dark'
        mode="inline"
        defaultSelectedKeys={defaultSelectedKeys}
        defaultOpenKeys={defaultOpenMenuKeys}
      >
        {navList.map(({ title, subList }) => (
          <SubMenu key={title} title={title}>
            {subList.map(({ title, path, privilege }) => {
              return (
                <Menu.Item key={path}>
                  <RouteLink path={path}>
                    {title}
                  </RouteLink>
                </Menu.Item>
              );
            })}
          </SubMenu>
        ))}
      </Menu>
    </Layout.Sider>
  );
};

export default memo(SideBar, () => true);
