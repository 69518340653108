import { ReactComponentElement } from 'react';
import { RouteProps as ReactRouteProps } from 'react-router-dom';
import { Privileges } from '../../privilege/@types/privilege.d';

export interface RouteProps extends ReactRouteProps {
  lazyComponent: ReactComponentElement,
  privilege?: Privileges,
};

/**
 * route paths
 */
export enum RoutePaths {
  // 首頁
  HOME = '/',

  // 公告
  BULLETIN = '/bulletin',
  // 公告新增編輯
  BULLETIN_EDITOR = '/bulletin-editor',

  // 廣告管理
  AD = '/ad',

  // 客訴
  CUSTOMER_CASE = '/customer-case',
  // 客訴新增編輯
  CUSTOMER_CASEE_EDITOR = '/customer-case-editor',
  // 客訴單歷程
  CUSTOMER_CASE_HISTORY = '/customer-case-history/:caseId',

  // 抽獎管理
  LOTTERY = '/lottery',
  // 抽獎內容
  LOTTERY_CONTENT = '/lottery-content/:itemid',
  // 抽獎新增編輯
  LOTTERY_EDITOR = '/lottery-editor',

  // 好康活動
  PROFIT_EVENT = '/profit-event',
  // 好康活動新增編輯
  PROFIT_EVENT_EDITOR = '/profit-event-editor',

  //遊戲伺服器管理
  GAME_LIST="/game",
  // 新增、更新遊戲(內容編輯)
  UPDATE_GAME="/game-editor",
  // 設定遊戲連結
  GAME_LINK_LIST="/game/link-list",
  // 帳號黑名單
  GAME_MEMBER_BLACK_LIST="/member-black-list/:gameId",
  // 遊戲伺服器列表
  GAME_SERVER_LIST="/game-server-list/:gameId",
  // 商品對應列表
  GAME_MAPPING_LIST="/game-mapping/:gameId",
  // 遊戲公告
  GAME_BULLETIN = '/game-bulletin',
  // 新增公告
  GAME_BULLETIN_EDITOR = '/game-bulletin-editor',

  // 點卡商品管理
  PRODUCT = '/product',
  SALESMONTHLYREPORT = '/salesMonthlyReport',
  VARIOUSSALESANALYSIS = '/variousSalesAnalysis',
  // 商品編輯
  PRODUCT_EDITOR = '/product-editor',
  // 商品遊戲對應 編輯
  PRODUCT_MAPPING_EDITOR = '/product-mapping-editor/:productId',

  // B2C 訂單列表
  B2C_ORDER = '/b2c-order-list',
  //B2C 歷史紀錄訂單
  B2C_ORDER_BANK_TRANSFER = '/b2c-order-list-banktransfer',
  // B2C 訂單內容
  B2C_ORDER_CONTENT = '/b2c-order/:orderid',
  //B2C 歷史紀錄訂單明细
  B2C_ORDER_BANK_TRANSFER_CONTENT = '/b2c-order-banktransfer/:orderid',

  // 發財金訂單
  BONUS_ORDER = '/bonus-order',

  // 點卡購物車
  CARD_CART = '/card-cart',
  // 更新商品點卡資訊
  UPDATE_CARD = '/update-cart/:productId',
  // 上傳點卡
  UPLOAD_CARD = '/upload-card',
  // 上傳點卡檔案
  UPLOAD_CARD_FILE = '/upload-card-file/:productId',
  // 點卡庫存
  CARD_INVENTORY = '/card-inventory',
  // 點卡上傳紀錄
  CARD_UPLOAD_HISTORY = '/card-upload-history',
  // 點卡列表
  CARD = '/card',
  // 批號列表
  BATCH = '/batch',
  // 下架點卡
  RETURN_CARD = '/return-card/:batchNo',
  // 查詢下架紀錄
  CARD_RETURN_HISTORY = '/card-return-history',

  // 廠商管理
  AGENCY = '/agency',
  // 廠商資訊編輯
  AGENCY_EDITOR = '/agency-editor',

  // 銷售紀錄
  TRADE_LOG = '/tradelog',
  // 銷售紀錄統計
  TRADE_LOG__DAILY_STATISTICS = '/tradelog/daily-statistics',
  // 銷售紀錄日報表
  TRADE_LOG__DAILY = '/tradelog/tradelog-daily',
  // 銷售紀錄月報表
  TRADE_LOG__MONTHLY = '/tradelog/tradelog-monthly',

  // 玉山 轉帳紀錄管理
  E_SUN = '/e-sun',
  // 玉山 轉帳紀錄
  E_SUN_LOGS = '/e-sun/logs',
  E_SUN_LOGS_BANK_TRANSFER = '/e-sun/logs_banktransfer',
  // 玉山 爭議紀錄
  E_SUN_MANUAL_LOGS = '/e-sun/manual-logs',
  // 玉山交易紀錄明細
  E_SUN_LOG_DETAIL = '/e-sun/log-detail/:msgSeq',
  // 玉山交易紀錄編輯
  E_SUN_EDIT_LOG_DETAIL = '/edit-log-detail/:msgSeq',
  // 玉山 儲值紀錄報表
  E_SUN_DEPOSIT_LOGS = '/e-sun/deposit-logs',
  // 儲值開啟/關閉管理
  TOP_UP_TOGGLE = "/e-sun/top-up-toggle",

  // ibon 繳款紀錄管理
  IBON = '/ibon',
  // ibon 繳款紀錄
  IBON__LOGS = '/ibon/logs',
  // ibon 繳款紀錄資訊
  IBON_LOG_INFO = '/ibon-log-info/:orderNo',
  // ibon 編輯繳款紀錄資訊
  IBON_LOG_EDITOR = '/manual-ibon-log/:orderNo',
  // ibon 取單紀錄
  IBON__TO_LOGS = '/ibon/to-logs',
  // ibon 取單紀錄資訊
  TO_IBON_LOG_INFO = '/to-ibon-log-info/:detailNum',
  // ibon 儲值紀錄報表
  IBON__DEPOSIT_LOGS = '/ibon/deposit-logs',

  // famiport 繳款紀錄管理
  FAMIPORT = '/famiport',
  // famiport 繳款紀錄
  FAMIPORT_LOGS = '/famiport/logs',
  // famiport 繳款紀錄詳細
  FAMIPORT_LOG_INFO = '/famiport-log-info/:orderNo',
  // famiport 繳款紀錄人工確認
  FAMIPORT_LOG_EDITOR = '/manual-famiport-log/:orderNo',
  // famiport 儲值報表
  FAMIPORT_DEPOSIT_LOGS = '/famiport/deposit-logs',
  // famiport 開放會員全家代碼
  FAMIPORT_MEMBERS = '/famiport/members',

  // 萊爾富繳款紀錄
  HILIFE = '/hilife',
  // 萊爾富繳款紀錄
  HILIFE_LOGS = '/hilife/logs',
  // 萊爾富繳款紀錄詳細
  HILIFE_LOG_INFO = '/hilife-log-info/:orderNo',
  // 萊爾富繳款紀錄詳細
  HILIFE_LOG_EDITOR = '/manual-hilife-log/:orderNo',
  // 萊爾富儲值報表
  HILIFE_DEPOSIT_LOGS = '/hilife/deposit-logs',

  // 信用卡繳款紀錄
  ALLPAY = '/allpay',
  // 信用卡繳款紀錄
  ALLPAY_LOGS = '/allpay/logs',
  // 信用卡繳款紀錄人工確認
  ALLPAY_LOG_EDITOR = '/manual-allpay-log/:msgseq',
  // 信用卡儲值報表
  ALLPAY_DEPOSIT_LOGS = '/allpay/deposit-logs',

  // 綠界ATM管理
  ECPAY = '/ecpay',
  // 綠界ATM繳款紀錄
  ECPAY_LOGS = '/ecpay/logs',
  // 綠界ATM繳款紀錄
  ECPAY_LOG_INFO = '/ecpay-log-info/:msgseq',
  // 綠界ATM繳款紀錄人工確認
  ECPAY_LOG_EDITOR = '/manual-ecpay-log/:msgseq',
  // 綠界ATM儲值報表
  ECPAY_DEPOSIT_LOGS = '/ecpay/deposit-logs',

  // 國泰
  CATHY = '/cathyatm',
  // 國泰交易紀錄
  CATHY_LOGS = '/cathyatm/logs',
  // 國泰爭議紀錄
  CATHY_MANUAL_LOGS = '/cathyatm/manaul-logs',
  // 非Web國泰交易紀錄
  CATHY_NO_WEB_LOGS = '/cathyatm/no-web-logs',
  // 非Web國泰爭議紀錄
  CATHY_MANUAL_NO_WEB_LOGS = '/cathyatm/no-web-manaul-logs',
  // 國泰交易紀錄明細
  CATHY_LOG_INFO = '/cathyatm-log-info/:msgseq',
  // 國泰交易紀錄編輯
  CATHY_LOG_EDITOR = '/manaul-cathyatm-log/:msgseq',
  // 國泰儲值報表
  CATHY_DEPOSIT_LOGS = '/cathyatm/deposit-logs',
  // 國泰即時入金
  CATHY_DATA_LINK = '/cathyatm/data-link',
  // 國泰即時入金
  CATHY_DATA_LINK_LOGS = '/cathyatm/data-link-logs',

  // 發票管理
  INVOICE = '/invoice',
  // 發票列表
  INVOICE_LIST = '/invoice/list',
  // 發票內容
  INVOICE_DETAIL = '/invoice-info',
  // 發票上架
  INVOICE_ON = '/invoice/invoice-on',
  // 發票下架
  INVOICE_OFF = '/invoice/invoice-off',
  // 發票上下架紀錄
  INVOICE_ON_OFF_LOGS = '/invoice/on-off-logs',
  // 發票剩餘張數
  INVOICE_REMAIN = '/invoice/remain',
  // 發票捐贈清單列表
  INVOICE_DONATE_LIST = '/invoice/donate-list',
  //折讓單列表
  INVOICE_ALLOWANCE_LIST = '/invoice/allowance-list',
  //折讓單明細頁
  INVOICE_ALLOWANCE_DETAIL = '/allowance-detail/:allowanceNum',
  //手動產生發票
  INVOICE_CREATE_PAGE = '/invoice/create-invoice',
  //手動產生折讓單
  INVOICE_CREATE_ALLOWANCE = '/invoice/create-allowance',
  //統一發票對獎
  INVOICE_CHECK_LOTTERY = '/invoice/check-lottery',
  //中獎發票列表
  INVOICE_PRIZE_LIST = '/invoice/invoice-prize',
  // 發票補印
  INVOICE_REPRINT = '/invoice/reprint',


  // 退款申請管理
  REFUND = '/refund',
  // 退款申請單列表
  REFUND_APPLY = '/refund/apply',
  // 退款紀錄列表
  REFUND_LIST = '/refund/logs',

  // 發財金管理
  BONUS = '/bonus',
  // 發財金進出紀錄
  BONUS_LOG = '/bonus-log/:memberId',
  // 發財金手動儲值/扣點
  BONUS_MANUAL = '/bonus-manual',
  // 發財金變動查詢
  BONUS_REMAIN = '/bonus_remain',
  // 發財金序號管理
  BONUS_CARD = '/bonus-card',
  // 發財金序號資訊
  BONUS_CARD_INFO = '/bonus-card-info/:batchNo',
  // 發財金序號產生
  BONUS_CARD_GENERATOR = '/bonus-card-generator',
  // 發財商城開啟/關閉管理
  BONUS_MALL = '/bonus-mall',

  // B2C 會員
  MEMBER = '/member',
  // 會員資訊編輯
  MEMBER_INFO = '/member-info/:memberId',
  // 會員發票資訊修改歷程紀錄
  MEMBER_INVOICE_HISTORY = '/member-invoice-history/:memberId',
  // 會員聯絡資訊修改紀錄 (手機、信箱)
  MEMBER_CONTACT_MODIFY_HISTORY = '/member-contact-modify-history/:memberId',
  // 會員登入歷程
  MEMBER_LOGIN_HISTORY = '/member-login-history',
  // 會員手機黑名單
  MEMBER_BLOCK_MOBILEPHONE = '/member-block-mobilephone',
  // 會員 ip 黑名單
  MEMBER_BLOCK_IP = '/member-block-ip',
  //银行账户黑名单
  MEMBER_BLOCK_ACCOUNT = '/member-block-account',
  // 付款地點報表查詢 (風控搜尋)
  MEMBER_PAYMENT_REPORT = '/member-payment-report',
  // 會員系統訊息
  MEMBER_MSG = '/member-msg/:mrscId',

  // 系統登入訊息
  SYSTEM_MSG = '/system-msg',
  // 系統登入訊息 新增編輯
  SYSTEM_MSG_EDITOR = '/system-msg-editor',

  // 站台管理室
  ADMIN = '/admin',
  // 管理員新增編輯
  ADMIN_EDITOR = '/admin-editor',

  // 權限群組
  PRIVILEGE_GRP = '/privilege-grp',
  // 權限群組新增編輯
  PRIVILEGE_GRP_EDITOR = '/privilege-grp-editor',

  // 重啟服務
  MAINTAIN_SYSTEM = '/maintain-system',

  // 登入
  LOGIN = '/login',
};

export interface RouteActionProps {
  path: RoutePaths | string,
  params?: { [propName: string]: string | number },
  search?: { [propName: string]: string | number | undefined },
  hash?: string,
  state?: { [propName: string]: any },
}
