import axios, { Method, AxiosRequestConfig, AxiosError } from 'axios';
import { notification, Modal } from 'antd';
import { RoutePaths } from '../components/router/@types/routes.d';
import { CHECK_LOGIN_API_URI } from './admin';

const instance = axios.create({
  headers: { 'Content-Type': 'application/json' },
  // timeout: 20000,
});

// axios.interceptors.request.use(function (config) {
//   // Do something before request is sent
//   // console.log(config);
//   return config;
// }, function (error: AxiosError) {
//   // Do something with request error
//   // console.log(error);
//   return Promise.reject(error);
// });

let hadShowLoginExpired = false;
instance.interceptors.response.use(function (response) {
  // Any status code that lie within the range of 2xx cause this function to trigger
  // Do something with response data
  if (!response.data) {
    return Promise.reject(response.data);
  }

  if (response.data?.error) {

    const {
      data,
      config,
    } = response;
    
    // match 9199 server url 
    if (config?.url?.startsWith('/')) {
      switch (data.error?.code) {
        // 回傳請先登入時，頁面轉至登入頁
        case 'error.privilege.noLogin':
          // 沒有顯示過登入提醒 且 回傳請先登入錯誤狀態的 response 不是來自檢查登入 API
          if (!hadShowLoginExpired && config.url !== CHECK_LOGIN_API_URI) {

            hadShowLoginExpired = true;

            Modal.error({
              title: '發生錯誤',
              content: '您尚未登入，或是登入逾時，請重新登入。',
              okText: '立即登入',
              onOk: (): void => { window.location.pathname = RoutePaths.LOGIN; },
            });
          }
          break;
        default:
          break;
      }
    }

    return Promise.reject(response.data.error);
  }

  return response;
}, function (error: AxiosError) {
  // Any status codes that falls outside the range of 2xx cause this function to trigger
  // Do something with response error
  if (axios.isCancel(error)) {
    return Promise.reject();
  }

  if (error.response?.status && error.response.status >= 400) {

    const {
      config,
      status,
      statusText,
    } = error.response;

    notification.error({
      message: '發生無法預期的錯誤',
      description: `錯誤代碼:「${status}」, 錯誤訊息: 「${statusText}」, URI: 「${config.url}」`,
      placement: 'bottomRight',
      duration: null,
    });

    return Promise.reject();
  }
  return Promise.reject(error);
});

function customAxios<T = any>(
  method: Method,
  url: string,
  data: FormData | Object | null = null,
  config?: AxiosRequestConfig,
) {
  switch (method.toUpperCase()) {
    case "POST":
      return instance.post<T>(url, data, config);
    case "DELETE":
      return instance.delete<T>(url, { params: data });
    case "PUT":
      return instance.put<T>(url, data);
    case "PATCH":
      return instance.patch<T>(url, data);
    case "GET":
    default:
      return config
        ? instance.get<T>(url, { params: data, ...config })
        : instance.get<T>(url, { params: data });
  }
};

export default customAxios;