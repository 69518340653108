import customAxios from './customAxios';
import { CancelToken } from 'axios';

const API_URI = '/BackWebAdmin/admin/AdminAction.do';
export const CHECK_LOGIN_API_URI = `${API_URI}?op=checkLogin`;

function checkLogin() {
  return customAxios(
    'GET',
    CHECK_LOGIN_API_URI,
  );
}

export interface LoginProps {
  adminId: string,
  passwd: string,
  passcode: string,
  authFile: File
}

function login({
  adminId,
  passwd,
  passcode,
  authFile,
}: LoginProps) {

  const formData = new FormData();
  formData.append('adminid', adminId.trim());
  formData.append('passwd', passwd.trim());
  formData.append('passcode', passcode.trim());
  formData.append('authfile', authFile);

  return customAxios(
    'POST',
    `${API_URI}?op=login`,
    formData,
    { headers: { 'Content-Type': 'multipart/form-data' }}
  );
}

function logout() {
  return customAxios(
    'POST',
    `${API_URI}?op=logout`,
  );
}

function listAdmin(
  len: number | undefined = 20,
  nextStart: number,
) {
  return customAxios(
    'GET',
    `${API_URI}?op=list`,
    {
      start: nextStart,
      len,
    }
  );
}

function queryAdmin(adminId: string, cancelToken?: CancelToken) {
  return customAxios(
    'GET',
    `${API_URI}?op=query`,
    { adminid: adminId },
    { cancelToken }
  );
}

interface AdminActionForm {
  adminId: string,
  name: string,
  privilegeGrps: string[],
  email: string,
  phone?: string,
  remark?: string,
}

function createAdmin({
  adminId,
  passwd,
  name,
  privilegeGrps,
  email,
  phone,
  remark,
}: AdminActionForm & { passwd: string }) {

  const formData = new FormData();
  formData.append('adminid', adminId);
  formData.append('passwd', passwd);
  formData.append('name', name.trim());
  formData.append('email', email);
  phone && formData.append('phone', phone);
  remark && formData.append('remark', remark);

  privilegeGrps.forEach(privilege => {
    formData.append('privilegegrps[]', privilege);
  });

  return customAxios(
    'POST',
    `${API_URI}?op=create`,
    formData
  );
}

function updateAdmin({
  adminId,
  name,
  privilegeGrps,
  email,
  phone,
  remark,
}: AdminActionForm) {

  const formData = new FormData();
  formData.append('adminid', adminId);
  formData.append('name', name.trim());
  formData.append('email', email);
  phone && formData.append('phone', phone);
  remark && formData.append('remark', remark);

  privilegeGrps.forEach(privilege => {
    formData.append('privilegegrps[]', privilege);
  });

  return customAxios(
    'POST',
    `${API_URI}?op=update`,
    formData
  );
}

function updateAdminPasswd(adminId: string, passwd: string, newPasswd: string) {

  const formData = new FormData();
  formData.append('adminid', adminId);
  formData.append('passwd', passwd);
  formData.append('newpasswd', newPasswd);

  return customAxios(
    'POST',
    `${API_URI}?op=updPwd`,
    formData
  );
}

function suspensionAdmin(adminId: string) {
  return customAxios(
    'POST',
    `${API_URI}?op=delete`,
    { adminid: adminId }
  );
}

function unsuspensionAdmin(adminId: string) {
  return customAxios(
    'POST',
    `${API_URI}?op=undelete`,
    { adminid: adminId }
  );
}

function deleteAdmin(adminId: string) {
  return customAxios(
    'POST',
    `${API_URI}?op=delId`,
    { adminid: adminId }
  );
}

function dnldKeyFile(adminId: string) {
  return customAxios(
    'GET',
    `${API_URI}?op=dnldKeyFile`,
    { adminid: adminId },
    { responseType: 'blob' }
  );
}

function regenKeyfile(adminId: string) {
  return customAxios(
    'POST',
    `${API_URI}?op=regenKeyfile`,
    { adminid: adminId },
    { responseType: 'blob' }
  );
}

const adminAPI = {
  checkLogin, // 檢查是否登入
  login, // 登入
  logout, // 登出

  listAdmin, // 取得管理員列表
  queryAdmin, // 取得管理員資訊

  createAdmin, // 新增管理員
  updateAdmin, // 更新管理員
  updateAdminPasswd, // 更新管理員密碼

  suspensionAdmin, // 管理員停權
  unsuspensionAdmin, // 解除管理員停權
  deleteAdmin, // 刪除管理員

  dnldKeyFile, // 下載金鑰檔
  regenKeyfile, // 重新產生金鑰檔
};

export default adminAPI;