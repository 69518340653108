import styled from 'styled-components';
import { Space } from 'antd';
import Colors from './colors';

/**
 * Styled
 */
const LoadingContainer = styled.div`
	position: relative;
	display: flex;
	width: 100%;
	height: ${({ height }: { height: string }) => height ? height : '112px'};
	align-items: center;
	justify-content: center;
`;
const LoadingSpinCenter = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
`;
const StatusContainer = styled.div<{ contentPadding?: string, BGColor?: string }>`
	position: relative;
	width: 100%;
	padding: ${({ contentPadding }) => contentPadding ? contentPadding : '10px'};
	display: flex;
	flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
	background-color: ${({ BGColor }) => BGColor || 'transparent'};
`;

const SpaceContent = styled(Space).attrs<{ maxWidth?: string }>(({ maxWidth = '800px' }) => ({
  direction: 'vertical',
  size: 'large',
  style: {
    maxWidth,
  },
}))<{ maxWidth?: string }>`
  width: 100%;
`;

const DescriptionsWrapper = styled.div`
  max-width: ${({ maxWidth = '100%' }: { maxWidth?: string }) => maxWidth};
  padding: 12px;
  border-radius: 3px;
  background-color: #FFF;
`;

const SearchBoard = styled.div`
  max-width: ${({ maxWidth = '760px' }: { maxWidth?: string }) => maxWidth};
  border: 1px solid #d9d9d9;
  background-color: #fbfbfb;
  border-radius: 2px;
  padding: 20px 20px 0;
`;
const SearchBoardCollapseBtn = styled.div`
  display: inline-block;
  color: ${Colors.MAIN_COLOR};
  font-size: 13px;
  cursor: pointer;

  &:hover {
    color: ${Colors.MAIN_COLOR_LIGHT};
  }
`;

const WrappedContent = styled.div`
  word-break: break-word;
  word-wrap: break-word;
`;

const LoadingSpinSearchBoard = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  z-index: 1;
`;

const GlobalStyle = {
  LoadingContainer,
  LoadingSpinCenter,
  StatusContainer,
  LoadingSpinSearchBoard,

  SpaceContent,
	DescriptionsWrapper,
  SearchBoard,
  SearchBoardCollapseBtn,
  WrappedContent
};

export default GlobalStyle;