import { ConfigProvider } from 'antd';
import { BrowserRouter } from 'react-router-dom';
import './App.less';
import 'dayjs/locale/zh-tw';
import zhTW from 'antd/lib/locale/zh_TW';
import 'moment/locale/zh-tw';
import RootView from './components/RootView';

/**
 * Variables
 */

/**
 * Main Component
 */
const App = () => (
  <ConfigProvider locale={zhTW}>
    <BrowserRouter>
      <RootView />
    </BrowserRouter>
  </ConfigProvider>
);

export default App;