import { memo, ReactNode } from 'react';
import { Link } from 'react-router-dom';
import { getRouteData } from './routes';
import { RouteActionProps } from './@types/routes.d';

interface RouteLinkProps extends RouteActionProps {
  children: ReactNode,
}

const RouteLink = (props: RouteLinkProps) => {

  const {
    children,
    ...routeProps
  } = props;

  return (
    <Link to={getRouteData(routeProps)}>
      {children}
    </Link>
  );
};

export default memo(RouteLink);
